<template>
	<main class="container-wide">
		<div class="flex space-between mb-40">
			<div>
				<h1 class="mb-10">Terminalar</h1>

				<p class="color-grey">Yvirlit yvir terminalar</p>
			</div>

			<div class="flex align-center">
				<div class="mr-10 relative search-input small">
					<input
						ref="searchInput"
						type="text"
						class="focus:background-light-grey-100"
						v-model="searchString"
						@keydown.esc="searchString = ''"
						placeholder="Leita"
					/>

					<div class="icon-search"></div>
				</div>

				<router-link class="btn-add" :to="{ name: 'TerminalCreate' }" title="Nýggjan terminal"></router-link>
			</div>
		</div>

		<div class="flex justify-center" v-if="loading">
			<i class="fas fa-spinner fa-spin fa-2x color-blue"></i>
		</div>

		<div class="mb-30">
			<label class="inline-flex align-center cursor-pointer">
				<div class="switch" :class="{ active: filterByLease }">
					<input type="checkbox" v-model="filterByLease" />

					<div></div>
				</div>

				<div class="inline-block ml-10">Vís einans leiguterminalar</div>
			</label>
		</div>

		<div v-if="!loading && !terminals.length" class="text-grey text-center">Eingin terminalur er skrásettur</div>

		<div v-if="!loading && terminals.length" class="table-container">
			<table>
				<thead>
					<th>&nbsp;</th>

					<th>Sáttmáli</th>

					<th>Verifone SR</th>

					<th>Verifone NR</th>

					<th>Model</th>

					<th>Leiguterminalur</th>

					<th>&nbsp;</th>
				</thead>

				<tbody>
					<tr v-for="(terminal, index) in filteredTerminals" :key="`terminal-${index}`">
						<td>
							<i v-if="hasOverlap(terminal)" title="Hesin terminalurin hevur yvirlapp" class="fas fa-exclamation-circle color-red"></i>
						</td>

						<td>
							<div v-if="getCustomer(terminal)">
								<router-link :to="{ name: 'AgreementShow', params: { id: getTerminalAgreement(terminal).id } }" class="hover:color-blue">
									{{ getCustomer(terminal).name }}
								</router-link>

								<span v-if="terminal.isTemporarilyOnAgreement" title="Fyribils á avtalu" class="ml-5">
									<i class="fas fa-stopwatch color-green"> </i>
								</span>
							</div>

							<div v-if="!getCustomer(terminal)">-</div>
						</td>

						<td>
							<a href="#" @click.prevent="showTerminal(terminal.id)" class="hover:color-blue">
								{{ terminal.verifoneSerialnumber }}
							</a>

							<span v-if="terminal.isSupport" title="Support terminalur" class="ml-5"><i class="fas fa-life-ring color-red"></i></span>
						</td>

						<td>
							<a href="#" @click.prevent="showTerminal(terminal.id)" class="hover:color-blue">
								{{ terminal.verifoneNumber }}
							</a>
						</td>

						<td>{{ terminal.modelName }}</td>

						<td>
							<span v-if="terminal.lease"><i class="fas fa-check-circle color-blue"></i></span>
						</td>

						<td class="right whitespace-nowrap">
							<i class="far fa-trash-alt cursor-pointer mr-20" @click="deleteTerminal(terminal.id)"></i>

							<i class="far fa-edit cursor-pointer" @click.stop="editTerminal(terminal.id)" title="Rætta terminal"></i>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</main>
</template>

<script>
import axios from 'axios';

export default {
	name: 'Terminals',

	data() {
		return {
			loading: true,
			searchString: '',
			terminals: [],
			filterByLease: false,
		};
	},

	computed: {
		filteredTerminals() {
			return this.terminals.filter((terminal) => {
				const fields = ['icc', 'psam', 'verifoneSerialnumber', 'verifoneNumber', 'phoneNumber'];

				if (this.filterByLease && !terminal.lease) {
					return false;
				}

				for (const field of fields) {
					if (!terminal[field]) {
						continue;
					}

					if (terminal[field].indexOf(this.searchString) > -1) {
						return true;
					}
				}

				return false;
			});
		},
	},

	mounted() {
		this.$refs.searchInput.focus();

		axios
			.get('/terminals')
			.then((response) => {
				const terminals = response.data;

				this.terminals = terminals;
			})
			.catch((error) => {
				console.log('Error fetching terminals', error);
			})
			.finally(() => {
				this.loading = false;
			});
	},

	methods: {
		dateOverlaps(startA, endA, startB, endB) {
			if (startA <= startB && startB <= endA) {
				return true;
			}

			if (startA <= endB && endB <= endA) {
				return true;
			}

			if (startB < startA && endA < endB) {
				return true;
			}

			return false;
		},

		hasOverlap(terminal) {
			if (!terminal.agreementTerminals.length) {
				return;
			}

			for (const agreementTerminal of terminal.agreementTerminals) {
				for (const sibling of terminal.agreementTerminals) {
					if (sibling.id == agreementTerminal.id) {
						continue;
					}

					const [startA, endA, startB, endB] = [
						new Date(agreementTerminal.to ?? '2100-01-01').getTime(),
						new Date(agreementTerminal.from ?? '2100-01-01').getTime(),
						new Date(sibling.from ?? '2100-01-01').getTime(),
						new Date(sibling.to ?? '2100-01-01').getTime(),
					];

					const overlap = this.dateOverlaps(startA, endA, startB, endB);

					if (overlap) {
						return true;
					}
				}
			}

			return false;
		},

		getTerminalAgreement(terminal) {
			const terminalAgreements = terminal.agreementTerminals;

			const lastTerminalAgreement = terminalAgreements[terminalAgreements.length - 1];

			if (!lastTerminalAgreement) {
				return null;
			}

			return lastTerminalAgreement.agreement;
		},

		getCustomer(terminal) {
			const terminalAgreement = this.getTerminalAgreement(terminal);

			if (!terminalAgreement) {
				return null;
			}

			return terminalAgreement.customer;
		},

		showTerminal(terminalId) {
			this.$router.push({ name: 'TerminalShow', params: { id: terminalId } });
		},

		editTerminal(terminalId) {
			this.$router.push({ name: 'TerminalEdit', params: { id: terminalId } });
		},

		deleteTerminal(terminalId) {
			if (!confirm('Ert tú vís/ur?')) {
				return;
			}

			axios
				.delete(`${process.env.VUE_APP_TERMINAL_API_URL}/terminals/${terminalId}`)
				.then(() => {
					const index = this.terminals.findIndex((item) => item.id == terminalId);

					this.terminals.splice(index, 1);
				})
				.catch((error) => {
					console.log('Error deleting terminal', error);
				});
		},
	},
};
</script>

<style scoped>
.table-container {
	overflow-x: auto;
}
</style>
